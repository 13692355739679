/* You can add global styles to this file, and also import other style files */

@import './base/reset';
@import 'bootstrap/scss/mixins/grid';
@import './output.scss';
@import './base/fonts';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "../../node_modules/angular-calendar/css/angular-calendar.css";

html, body {
    // height: 100%;
    // width: 100%;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

body {
  background: $color-main-semi-light;
  color: $color-neutral;
  font-family: 'Roboto', sans-serif;
  font-weight: $weight-regular;
  letter-spacing: 0.5px;
  font-size: 12px;
  // margin-left: $side-nav-width;

  &.no_scroll {
    position: fixed;
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4 {
  color: $color-neutral-dark;
  margin-bottom: 5px;
}

h1 {
  display: inline-block;
  font-size: 32px;
  line-height: 48px;
  margin: 14px 0;

  &.gradient {
    background: $color-brand-gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 42px;
  }

  &.large {
    font-size: 72px;
  }
}

h2 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.2px;
}

h4 {
  color: $color-neutral;
  font-size: 18px;
}

p,
a,
span {
  font-size: 12px;

  &.fine_print {
    font-size: 11px !important;
    line-height: 14px !important;
    font-style: italic;
    margin: 0;
  }
}

p {
  margin-bottom: 10px;
  line-height: 20px;
}

a {
  cursor: pointer;
  color: $color-brand;
  text-decoration: none;

  &.delete {
    color: $color-red;
  }

  &:hover {
    color: $color-brand;
  }

  &.section--cta {
    color: $color-neutral;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: $weight-regular;
  }
}

hr {
  height: rem(1px);
  background-color: $color-main-semi-light2;
  border: none;
  width: 100%;
}

tr {
  vertical-align: middle;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
mat-table {
  border: 1px solid $color-border;
  border-radius: 6px;
  overflow: hidden;
}

form {
  width: 100%;
}

.button {
  font-weight: $weight-semi-bold;
  font-size: rem(14px);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem(50px);
  padding: 18px 40px;
  margin: rem(20px) 0;
  cursor: pointer;
  @include transition(0.2s ease all);

  &.small {
    height: 38px;
    padding: 8px 12px;
    border-radius: 6px;
    font-weight: $weight-regular;

    .icon {
      padding-right: 5px;
      font-size: 16px;
    }
  }

  &.primary {
    background-color: $color-brand;
    color: $color-main;

    &:hover {
      @include transition(0.2s ease all);
      background-color: $color-brand-dark;
    }
  }

  &.neutral {
    background-color: $color-main-semi-light;
    color: $color-neutral;
    border: 1px solid $color-main-semi-light2;

    &:hover {
      @include transition(0.2s ease all);
      background-color: $color-main-semi-light2;
    }
  }

  &.disabled {
    background-color: $color-main-semi-light;
    color: $color-main-light;
    cursor: not-allowed;
    border: 1px solid $color-main-semi-light2;

    &:hover {
      background-color: $color-main-semi-light;
      color: $color-main-light;
    }
  }

  &.full {
    width: 100%;
  }

  &.off_theme {
    background-color: $color-main-semi-light;
    color: $color-main-light;
  }

  &.subtle {
    background-color: $color-main-semi-light2;
    color: $white;

    &:hover {
      @include transition(0.2s ease all);
      background-color: $color-border;
    }
  }

  &.empty {
    background-color: unset;
    color: $color-neutral;
    // width: 100%;
    // border: rem(1px) solid $color-neutral;

    &:hover {
      @include transition(0.2s ease all);
      color: $color-neutral-light;
      // border: rem(1px) solid $color-neutral-light;
    }
  }
}

input,
textarea {
  background-color: $color-main-semi-light;
  border: rem(1px) solid #2f2e33;
  color: $color-neutral;
  font-size: rem(12px);
  caret-color: $color-brand;
  box-shadow: 0 0 0 $color-brand;
  border-radius: 4px;
  padding: rem(5px) rem(20px);
  // height: rem(36px);

  &:focus {
    @include transition(0.3s ease all);
    outline: none !important;
    border-color: $color-brand;
    box-shadow: 0 0 5px $color-brand;
  }

  &:-webkit-autofill::first-line {
    font-size: rem(16px);
  }

  &.full {
    width: 100%;
    border-radius: rem(50px);
    padding: rem(16px) rem(40px);
    margin: rem(10px) 0 rem(20px);
  }

  &.title {
    height: 60px;
  }

  &.icon {
    padding-left: rem(40px);
  }

  &.error {
    border: 1px solid $color-red;
  }

  &.editable {
    background-color: unset;
    border: none !important;
    padding: 20px 0;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }

    &.editing {
      background-color: $color-main-semi-light2;
    }
  }
}

.emphasis-text {
  font-style: italic !important;
  color: $color-main-light;
}

.form-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .three-quarter-width, .half-width {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  .third-width {
    margin-right: 20px;
    
    &:last-child {
      margin-right: 0;
    }

    // &:nth-child(2) {
    //   margin-right: 0;
    // }
  }

  .quarter-width {
    margin-right: 20px;
    
    &:last-child {
      margin-right: 0;
    }

    // &:nth-child(2) {
    //   margin-right: 0;
    // }
  }
}

.full-width {
  width: 100%;
  margin-right: 0 !important;
}

.three-quarter-width {
  max-width: 75%;

  &.gap {
    width: calc(75% - 10px);
  }
}

.half-width {
  max-width: 50%;

  &.gap {
    width: calc(50% - 10px);
  }
}

.quarter-width {
  max-width: 25%;

  &.gap {
    width: calc(25% - 10px);
  }
}

.third-width {
  max-width: 33.333333333%;

  &.gap {
    width: calc(33.333333333% - 10px);
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

textarea {
  width: 100%;
  min-height: 100px !important;
  padding: 10px 15px;
  height: 100% !important;
}

.loader {
  border: 4px solid $color-accent;
  border-top: 4px solid $color-neutral-dark;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  &.small {
    width: 20px;
    height: 20px;
    border-width: 3px;
    border-top-width: 3px;
  }

  &.medium {
    width: 30px;
    height: 30px;
    border-width: 3px;
    border-top-width: 3px;
  }

  &_container {
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      margin-left: 10px;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $color-main-semi-light;
  border: rem(1px) solid #2f2e33;
  color: $color-neutral;
  -webkit-text-fill-color: $color-neutral;
  -webkit-box-shadow: 0 0 0px 1000px $color-main-semi-light inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: rem(16px);
}

input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  background-color: $color-main-semi-light;
  border: rem(1px) solid #2f2e33;
  color: $color-neutral;
  -webkit-text-fill-color: $color-neutral;
  -webkit-box-shadow: 0 0 0px 1000px $color-main-semi-light inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: rem(16px);
  outline: none !important;
  border-color: $color-brand;
  box-shadow: 0 0 10px $color-brand;
}

.cal_has_event {
  &::after {
    position: absolute;
    width: 4px;
    height: 4px;
    content: '';
    background-color: $color-accent-secondary;
  }
}

.page_wrapper {
  padding: 20px;
}

.calendar, .cal-month-view, .cal-month-view .cal-cell.cal-has-events.cal-open, .cal-month-view .cal-day-cell.cal-today {
  // background-color: $color-main !important;
}

.cal-month-view .cal-cell-row:hover, .cal-month-view .cal-cell-row .cal-cell:hover {
  // background-color: unset !important;
}

.cal-month-view .cal-days {
  border-color: $color-main-semi-light2 !important;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: $color-main-semi-light2 !important;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: $color-main-semi-light2 !important;
}

.cal-month-view .cal-header {
  padding: 0;
  // background-color: $color-main !important;
  // border-top-left-radius: 6px;
  // border-top-right-radius: 6px;
  border: 1px solid $color-main-semi-light2;
  border-bottom: none;

  // &:hover {
  //   background-color: $color-main-semi-light !important;
  // }
}

.cal-month-view .cal-header .cal-cell {
  border-right: 1px solid $color-main-semi-light2;
  padding: 10px 20px;
  text-align: left;
  color: #a9a9a9;
}

.cal-month-view .cal-day-cell {
  min-height: 120px;
  width: 160px;
}

.cal-out-month .date {
  color: $color-main-semi-light2 !important;
}

.cal-day-cell.cal-today .date {
  border-radius: 100px;
  background-color: $color-accent-secondary;
  padding: 10px;
  color: $color-main !important;
  top: 10px !important; 
  left: 10px !important;
}

.cal-week-view .cal-header.cal-today {
  border-top: 4px solid $color-accent-secondary !important;
  // background-color: $color-main !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 24px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-button-toggle-checked {
  background-color: $color-brand !important;
  color: $color-main !important;
}

.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-calendar-content {
  padding: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-frame {
  border-radius: 4px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
  border-radius: 4px !important;
  background-color: $color-accent !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent .mat-ripple-element, .mat-mdc-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: $color-accent !important;
}

.cal-week-view .cal-hour-odd {
  // background-color: $color-main !important;
}

.mat-datepicker-toggle .mat-mdc-icon-button {
  position: absolute;
  right: 0;
  top: 0;
}

input {
  &.mat-mdc-input-element {
    padding-left: 8px;
    height: 28px;

    &.title {
      height: 40px;
      font-size: 20px;
    }
  }
}

.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
  line-height: 22px !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  // border: 1px solid $color-border;
}

.mat-expansion-panel-header-title {
  font-size: 18px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
  padding: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-label {
  background-color: $color-main;
}

.mat-mdc-expansion-panel-content {
  background-color: $color-main-semi-light;
}

.mat-mdc-expansion-panel {
  &:last-child {
    .mat-mdc-expansion-panel-header {
      border-bottom: 1px solid $color-border;
    }
  }
}

.mat-mdc-expansion-panel-header {
  border-top: 1px solid $color-border;
  height: 64px !important;

  &.mat-mdc-expanded {
    border-bottom: 1px solid $color-border;
  }
}

.mat-mdc-expansion-panel-body {
  padding-top: 16px !important;
}

table {
  width: 100%;
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

td, th {
  // width: 25%;
  // min-width: 100px;

  &.small {
    min-width: 40px;
  }
}

th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
  padding: 0 8px !important;
}

th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
  padding-left: 24px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-labels {
  display: block !important;
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-mdc-tab-body.mat-mdc-tab-body-active {
  z-index: 998 !important;
}

.mat-mdc-form-field {
  flex: 1;
}
.mat-mdc-form-field{
  min-width: unset !important;
}
.mat-mdc-button:not(:disabled){
  letter-spacing: inherit;
}
.mdc-button{
  font-weight: 600 !important;
  letter-spacing: inherit;
}
.mdc-button.primary{
  color: #fff !important;
}
.mdc-tab__text-label{
  letter-spacing: normal;
}
.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,.mdc-text-field--filled .mdc-floating-label{
  letter-spacing: normal !important;
}
.mat-datepicker-toggle .mat-mdc-icon-button{
  top: -20px;
}
tr.mat-row.mat-mdc-no-data-row .mat-cell {
  padding: 10px !important;
}
.mdc-card{
  padding: 15px;
}
.mat-mdc-card-content{
  padding: 0 !important;
  margin-bottom: 15px;
}
.mat-mdc-tab-label-container{
  margin-bottom: 15px;
}
.mat-mdc-option .mdc-list-item__primary-text{
  letter-spacing: normal !important;
  font-size: 14px !important;
}
.search-dropdown {
  line-height: 30px !important;
}
.mat-mdc-list-item.mdc-list-item--with-three-lines .mat-mdc-list-item-line.mdc-list-item__secondary-text{
  white-space: wrap !important;
}
.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mat-mdc-floating-label{
  font-size: 14px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label{
  font-size: 14px;
}
.client-detail-expansion-panel .mat-expansion-panel-body{
  padding: 5px!important;
}