@import 'mixins.scss';

$white: #fff;
$black: #000;

/* Brand Colors */
$color-main: #fff;
$color-main-dark: #111114;
$color-main-semi-light: #F4F6F8;
$color-main-semi-light2: #BFC6CA;
$color-main-light: #838D9C;
$color-brand: #215eac;
$color-brand-gradient: linear-gradient(135deg, rgba(33,94,172,1) 0%, rgba(255,192,40,1) 100%);
$color-brand-light: #2871d1;
$color-brand-extra-light: #a6caf9;
$color-brand-dark: #18447f;
$color-brand-extra-dark: #0e294d;
$color-brand-seconday: #46d63b;
$color-brand-seconday-extra-light: #a3fa9d;
$color-accent: #ffc028;
$color-accent-extra-light: #fbde9b;
$color-accent-dark: #dea623;
$color-accent-secondary: #ef335e;
$color-accent-secondary-extra-light: #ff99b1;
$color-accent-secondary-dark: #d12b52;
$color-accent-tertiary: #f09f65;
$color-neutral: #4d4d4f;
$color-neutral-dark: #404040;
$color-neutral-light: #6d6d6d;
$color-red: #ed7272;
$color-red-dark: #2a1c34;
$color-green: #72b879;
$color-green-dark: #132a3a;
$color-error: red;
$color-border: #d1d1d1;
$color-border-dark: #3a3a3a;

$discord-brand: #5865F2;
$twitter-brand: #1DA1F2;

/* Style Properties */
$weight-bold: 700;
$weight-semi-bold: 600;
$weight-medium: 500;
$weight-regular: 400;
$weight-light: 300;

/* Padding */
$padding-sm: rem(5px);
$padding-md: rem(10px);
$padding-lg: rem(15px);

/* Margin */
$margin-sm: rem(5px);
$margin-md: rem(10px);
$margin-lg: rem(15px);

/* Misc */
$side-nav-width: rem(260px);
$side-nav-width-collapsed: 80px;
$top-nav-height: rem(80px);
